import React, { useState, useEffect } from "react";
import DataCard from "./DataCard";
import "../css/popuptoolbar.css";
import { setGlobalState, useGlobalState } from "../state";
import { useParams } from "react-router-dom";
import { Fade, MenuList, MenuItem, Paper, Popper, Grow, ClickAwayListener, Divider } from "@mui/material";

const PopupToolbar = (props) => {
	const { loadID } = useParams();
	const [selectedButton, setSelectedButton] = useState(null);
	const [showDataCard, setShowDataCard] = useGlobalState("showDC");
	const [dataObject, setDataObject] = useState(null);
	const [showUC, setShowUC] = useGlobalState("showUC");
	const [startUC, setStartUC] = useState(false);
	const [useCaseID, setUseCaseID] = useState(null);
	const [isButtonContainer, setIsButtonContainer] = useGlobalState("IsButtonContainer");
	const [uc, setUc] = useState("");
	const [solutionsData, setSolutionsData] = useState(props.sectionData);
	const [solutionsId, setSolutionsId] = useGlobalState("solutionsId");
	const [currentSlide, setCurrentSlide] = useGlobalState("currentSlide"); 
	const [solutiondata, setSolutiondata] = useGlobalState("solutiondata");

	useEffect(() => {
		setDataObject(solutiondata);
	}, [solutiondata])

	useEffect(() => {
		if (dataObject) {
			console.log(dataObject);
			setShowDataCard(true);
			setGlobalState("showDC", true);
		}
	}, [dataObject]);  // This effect runs whenever dataObject is updated
	

	useEffect(() => {
		if (solutionsId != -1) {
			handleButtonClick(solutionsId);
		}
	}, [solutionsId]);

	const handleCloseClick = () => {
		setShowDataCard(false);
		setGlobalState("showDC", false);
		setGlobalState("IsBackgroundBlur", false);
		setSelectedButton(null);
	};

	const handleXCloseClick = () => {
		handleCloseClick();
		props.handleClose();
	};

	const handlePreButton = () => {
		document.getElementsByClassName("control-prev")[0]?.click();
		setCurrentSlide(currentSlide-1);
	};

	const handleNexButton = () => {
		document.getElementsByClassName("control-next")[0]?.click();
		setCurrentSlide(currentSlide+1);
	};

	useEffect(() => {
		setSolutionsData(props.sectionData);
		if (loadID != null) {
			handleButtonClick(loadID);
		}
	}, [loadID, props.sectionData]);

	function timeout(delay) {
		return new Promise(res => setTimeout(res, delay));
	}
	const handleButtonClick = async (buttonId) => {
		setGlobalState("IsBackgroundBlur", true);
		setShowDataCard(true);
		setGlobalState("showDC", true);
		setStartUC(false);

		try {
			setSelectedButton(buttonId);
			const obj = await solutionsData.find((element) => element.id === buttonId);
			setDataObject(obj);
			console.log(dataObject);
			if (!showUC) {
				setShowDataCard(true);
				setGlobalState("showDC", true);
			} else {
				const ucid = "uc" + String(buttonId);
				setUseCaseID(buttonId);
				setUc(ucid);
				setStartUC(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
		return;
	};

	useEffect(() => {
		handleCloseClick();
	}, [props.buttonType]);

	return (
		<div className="wrapper popup-wrapper">
			<div style={{ display: "flex" }}>
				<div className="popuptoolbar-container">
					{isButtonContainer &&
						<Popper
							anchorEl={props.anchorEl}
							open={props.open}
							TransitionComponent={Fade}
							role={undefined}
							placement="bottom-start"
							transition
							disablePortal
							className="popup-container"
						>
							{({ TransitionProps, placement }) => (
								<Grow
									{...TransitionProps}
									style={{
										transformOrigin:
											placement === 'bottom-start' ? 'left top' : 'left bottom',
									}}
								>
									<Paper>
										<ClickAwayListener onClickAway={() => { if (!props.cascading) props.handleClose(); }}>
											<MenuList
												autoFocusItem={props.open}
												id="fade-menu"
												aria-labelledby="fade-button"
												TransitionComponent={Fade}
											>
												{props.sectionData && props.sectionData.map((element) => {
													return (
														element.id == "divider" ?
															<Divider sx={{ bgcolor: 'white' }} />
															:
															<MenuItem
																className="popup"
																onClick={() => {
																	props.handleMenuItemClick();
																	handleButtonClick(element.id)
																}}>
																{element.short_label}
															</MenuItem>
													);
												})}
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					}
					<DataCard
						data={dataObject}
						onClose={handleXCloseClick}
						handlePreButton={handlePreButton}
						handleNexButton={handleNexButton}
						showCardContainer={showDataCard}
					/>
				</div>
			</div>
		</div>
	);
};

export default PopupToolbar;
