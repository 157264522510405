import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ToolbarButton from "../utils/libraries/ToolbarButton";
import MenuDispensor from "../utils/libraries/MenuDispensor";
import { useParams, useNavigate } from "react-router-dom";
import { setGlobalState, useGlobalState } from "../utils/state";
import { Howler, Howl } from "howler";
import { setIsUseCaseStoryOpen, getIsUseCaseStoryOpen } from '../utils/state/globalRef.js';
import { spiralAnimation, rotateToTarget, linearAnimation } from '../utils/libraries/CameraUtils';
import {
  Vector3,
  Viewport,
  Matrix
} from '@babylonjs/core';
import "../utils/css/mainPage.css";
import {
  BaseAPI,
  MainMenuIsButtons,
  ApplicationDB,
  assetsLocation,
  packageApp
} from "../assets/assetsLocation";
import usecases from '../data/usecases.json';
import { setTourState } from "../hooks/animations";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import { CSSTransition } from "react-transition-group";
import { startTransition } from "react";
import { resetLights } from "../utils/libraries/LightUtils";

const MainPage = (props) => {
  const location = useLocation();
  const buttonRef = useRef(null);
  const { toPress, loadID } = useParams();
  const [extraData, setExtraData] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [selectedButton, setSelectedButton] = useGlobalState("selectedButton");
  const [showCardContainer, setShowCardContainer] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [UcGuidedTour, setUcGuidedTour] = useGlobalState("UcGuidedTour");
  const [highlightCurrentCard, sethighlightCurrentCard] = useGlobalState("highlightCurrentCard");
  const [ui_Element, setUI_Element] = useState(null);

  const [showTour, setShowTour] = useGlobalState("showTour");
  const [buttonType, setButtonType] = useState("");
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [showUC, setShowUC] = useGlobalState("showUC");
  const [useCase, setUseCase] = useGlobalState("useCase");
  var guidedTourOpen = false;
  const [currentSound, setCurrentSound] = useState(null);
  const IsGuidedTourOpen = useRef(false); 
  const [isResetClick, setIsResetClick] = useState(false);
  const [useCaseMapping, setUseCaseMapping] = useState(false);
  const [HoverId, setHoverId] = useGlobalState("HoverId");
  const [HoverLabel, setHoverLabel] = useGlobalState("HoverLabel");
  const [clientXPosition1, setClientXPosition1] = useGlobalState("clientXPosition1");
  const [clientYPosition1, setClientYPosition1] = useGlobalState("clientYPosition1");
  const [isTourOpen, setIsTourOpen] = useGlobalState("IsTourOpen");
  const [isHomeButtonClick, setIsHomeButtonClick] =
    useGlobalState("IsHomeButtonClick");
  const [playAndPause, setPlayAndPause] = useGlobalState("playAndPause");
  const gaEventTracker = useAnalyticsEventTracker("ToolBarMenu");
  const [anchorEl, setAnchorEl] = useState(null);
  const [uCTourId, setUCTourId] = useGlobalState('UCTourId');
  const [linkType, setLinkType] = useState(null);
  const [scene, setScene] = useGlobalState("scene");
  let alignItems = false;
  const allHoverData = useRef([]);
  const showAllHover = useRef(false);
  const queryParams = new URLSearchParams(location.search);
  const [GuidedTourOpen, setGuidedTourOpen] = useGlobalState("GuidedTourOpen");
  var intro = queryParams.get('intro');

  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
//
    setAnchorEl(null);
    setSelectedButton("selectedButton")
    setUI_Element("");
  };
  const handleMenuItemClick = () => {
    setAnchorEl(null);
    // setSelectedButton("selectedButton")
  };
  const links = new Map([
    ["needs", "btnBusinessNeeds"],
    ["principles", "btnGuidingPrinciples"],
    ["challenges", "btnSalesChallenges"],
    ["solutions", "btnfasolutions"],
    ["use_case_stories", "btnUseCasesEnabled"]
  ]);

  // Set screen to initial state
  const resetScreen = () => {
    resetLights(scene);
    if (ui_Element == "welcome") {
      setUI_Element("");
    }
    setGlobalState("IsBackgroundBlur", false);
    setTourState(false);
    setIsTourOpen(false);
    setIsUseCaseStoryOpen(false);

    sethighlightCurrentCard(0);
    setSelectedButton(null);
    setShowCardContainer(false);
    setGlobalState("useCase", 0);
    IsGuidedTourOpen.current = false;
    setGlobalState("solutionsId", -1);
    setGlobalState("HoverUseCaseId", 0);
    setShowUC(false);
    setGlobalState("showDC", false);
    setGlobalState("showUC", false);
    //props.resetCamera();
    Howler.stop();
  };

  const [count, setCount] = useState(0);
  const handleNext = () => {
    startTransition(() => {
      if (count == 5) {
        handleSkip();
        // handleTourButtonClick("tour");


        document.getElementById("btnGuidedTour").click();
      }
      setCount(count + 1);
    });
  };
  const handleSkip = () => {
    setCount(0);
    resetScreen();
  };

  let WelcomeData = [
    "Why is Digitally Transforming a CPG business so challenging?",
    "But Digital Transformation initiatives have struggled to deliver",
    "Is there a better way?",
    "Explore this Experience center for a superior, proven approach",
    "Explore use cases in context: Retail Store, Distributor, Field, or HQ",
    "Let's see how you can leverage the FAi platform across your business",
  ];

  let WelcomeData1 = [
    "Technology has created big winners across industries. How can you make it work for your CPG business?",
    "Technology implementations have continued to disappoint, failing to deliver promised results and ROI",
    "Your business is unique. How can a generic transformation approach work for you? Create a business-led transformation with FieldAssist",
    "See the big picture. See the interconnections. Hit 'Reset' anytime to come back.",
    "Select any use case to get its complete overview and significance, along with the solutions available to you.",
    "You can stop the tour anytime you like using the 'stop tour' button on the bottom right.",
  ];

  useEffect(() => {
    if (selectedButton == "tour" && isTourOpen == false) {
      setSelectedButton(null);
    }
  }, [isTourOpen]);


  useEffect(() => {
    setGlobalState("GuidedTourOpen", isTourOpen);
  }, [isTourOpen, setGlobalState]);


  const bgMusic = new Howl({
		src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/uc_music/immersive.mp3` : `../../${ApplicationDB}/audio/uc_music/immersive.mp3`,
		loop: true,
		volume: 0.35,

	});
  const IsUseCaseStoryOpen = getIsUseCaseStoryOpen();
  useEffect(() => {
    if (isTourOpen && !IsUseCaseStoryOpen) {
      bgMusic.play();
    }
  }, [isTourOpen, IsUseCaseStoryOpen]);

  const handleTourButtonClick = (buttonId) => {
    if (!playAndPause) {
      setGlobalState("playAndPause", true)
    }
    setGlobalState("IsBackgroundBlur", false);
    if (selectedButton === buttonId) {
      resetScreen();
      return;
    } else {
      setTourState(true);
      setSelectedButton(buttonId);
      setUI_Element("");
      setShowCardContainer(false);
      setGlobalState("IsTourOpen", true);
      setGlobalState("useCase", 0);
      setGlobalState("HoverId", 0);
      Howler.stop();
    }
  };

  useEffect(() => {
    if (currentSound == null) return;
    if (!playAndPause) {
      // Howler.stop();
      currentSound.pause();
    } else {
      currentSound.play();
    }
  }, [playAndPause]);

  useEffect(() => {
    if (toPress != null) {
      if (toPress === "tour") {
        handleTourButtonClick(toPress);
      } else {
        handleButtonClick(links.get(toPress));
      }
    }
  }, [toPress]);

  // const handleHoverClick = async() => {
  // if (!IsGuidedTourOpen.current) {
  //   handlePlayStory()
  //   } 
  // }

  const handlePlayStory = async() => {
    if (HoverId !== 0) {
      if (typeof HoverId === 'string') {
        // Extract the numerical part using a regular expression
        let numericPart = HoverId.match(/\d+/)[0];

        // Convert the extracted string to a number
        let number = parseInt(numericPart);
        
        setGlobalState("HoverUseCaseId", number)
      }
      else {
        setGlobalState("HoverUseCaseId", HoverId);
      }
    }
    handleUseCaseButtonClick("btnUseCasesEnabled");
    setGlobalState("IsButtonContainer", false);
  }
  useEffect(() => {
    if (isHomeButtonClick) {
      // setUI_Element("");
      setGlobalState("useCase", 0);
      setGlobalState("HoverUseCaseId", 0);
      setSelectedButton(null);
      // setGlobalState("IsButtonContainer", false);
    }
  }, [isHomeButtonClick]);


  const handleUseCaseButtonClick = async (buttonId) => {
    setGlobalState("IsHomeButtonClick", false);
    setGlobalState("ApplicationDB", ApplicationDB);

    Howler.stop();
    setUI_Element("");
    if (selectedButton === buttonId) {
      // if same button clicked again, reset screen
      resetScreen();
      return;
    }
    setSelectedButton(buttonId);
    try {
      const baseAPIUrl = `${BaseAPI}use_case_list/?db=${ApplicationDB}`;
      const id = buttonId.at(-1);
      const address = !packageApp ? baseAPIUrl : `../../${ApplicationDB}/use_case_list.json`; //address for fetching sectiondata
      // const address = baseAPIUrl + id; //address for fetching sectiondata
      const response = await fetch(address); //fetch section data files for specific config id
      const data = await response.json();

      if (buttonId === "btnUseCasesEnabled") {
        setButtonType("Use_case");
        setGlobalState("IsButtonContainer", true);
        setUI_Element("popuptoolbar");
      } else {
        setUI_Element("cards");
      }
      setSectionData(data.use_case_list);

      setShowCardContainer(true);
      if (buttonId === "btnUseCasesEnabled") {
        setShowUC(true);
        setGlobalState("showUC", true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    return;
  };

  async function fetchData() {
    for (var id = 0; id < 9; id++) {
      var baseAPIUrl;
      var address;
      if (id === 8) {
        baseAPIUrl = `${BaseAPI}use_case_list/`;
        address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/use_case_list.json`; //address for fetching sectiondata
      } else if (id === 7) {
        baseAPIUrl = `${BaseAPI}solutions`;
        address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/solutions.json`; //address for fetching sectiondata
      } else {
        baseAPIUrl = `${BaseAPI}section/`;
        address = !packageApp ? `${baseAPIUrl + id}?db=${ApplicationDB}` : `../../${ApplicationDB}/section/${id}.json`; //address for fetching sectiondata
      }
      // CHANGES HERE
      try {
        const response = await fetch(address); //fetch section data files for specific config id
        const data = await response.json();
        extraData[id - 1].push(data);
        if (id === 8) {
          setGlobalState("use_case_list", data);
          extraData[7] = [[], [], []];
          data.use_case_list.forEach((element) => {
            if (element.use_case_id < 100) extraData[7][0].push(element);
            else if (element.use_case_id < 200) extraData[7][1].push(element);
            else if (element.use_case_id < 300) extraData[7][2].push(element);
          });
        }
        else if (id === 7) {
          extraData[6] = [[], [], []];
          data.Solutions.forEach((element) => {
            if (element.soln_type == "P") extraData[6][0].push(element);
            else if (element.soln_type == "F") extraData[6][1].push(element);
            else if (element.soln_type == "S") extraData[6][2].push(element);
          });
        }
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    }
  }


  async function fetchAudio() {
    const baseAPIUrl = `${BaseAPI}use_case_list/`;
    const address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/use_case_list.json`;
    const response = await fetch(address);
    const data = await response.json();

    let Vosound;
    const audioClips = new Map();
    const audio_Paths = new Map();

    data.use_case_list.forEach((uc) => {
      const id = uc.use_case_id;
      const src_url = !packageApp ?
        `${assetsLocation}${ApplicationDB}/audio/uc` + String(id) + "/" : `../../${ApplicationDB}/audio/uc${id}/`;
      const path = src_url + "10.mp3";
      try {
        Vosound = new Howl({
          src: path,

          onpause: false,
          preload: "metadata",
          onloaderror: (id, error) => {
            console.error("Failed to load audio:", error); // Log the error
        }});
        audioClips.set(id, Vosound);
        audio_Paths.set(id, path);
      } catch {
      }

    });
    setGlobalState("audioVO1", audioClips);
    setGlobalState("audioPathVO1", audio_Paths);

    const audioClips2 = new Map();
    const audio_Paths2 = new Map();
    data.use_case_list.forEach((uc) => {
      const id = uc.use_case_id;
      const src_url = !packageApp ?
        `${assetsLocation}${ApplicationDB}/audio/uc` + String(id) + "/" : `../../${ApplicationDB}/audio/uc${id}/`;
      const path = src_url + "11.mp3";
      try {
        Vosound = new Howl({
          src: path,

          onpause: false,
          preload: "metadata",
        });
        audioClips2.set(id, Vosound);
        audio_Paths2.set(id, path);
      } catch {
      }
    });
    setGlobalState("audioVO2", audioClips2);
    setGlobalState("audioPathVO2", audio_Paths2);
  }

  useEffect(() => {
    fetchData();
    fetchAudio();
  }, []);

  const handleButtonClick = async (buttonId) => {

    setSelectedButton(buttonId);
    setTourState(false);
    if (!playAndPause) {
      setGlobalState("playAndPause", true);
    }
    setGlobalState("IsAutoPlay", false);
    setGlobalState("IsHomeButtonClick", false);
    setGlobalState("ApplicationDB", ApplicationDB);
    // if (isTourOpen) {
    //   setGlobalState("UCTourId", 0);
    //   // document.getElementById("close-btn").click();
    //props.resetCamera();
    // }
    Howler.stop();
    setUI_Element("");

    setShowCardContainer(true);

    return;
  };

  const handleResetButtonClick = () => {
    if (selectedButton == "btnUseCasesEnabled") {
      setUI_Element(null);
    }
    // setUseCaseMapping(false);
    resetLights(scene);
    sethighlightCurrentCard(0);
    setIsUseCaseStoryOpen(false);
    setGlobalState("IsBackgroundBlur", false);
    if (MainMenuIsButtons) {
      setIsResetClick(true)
    }
    setTimeout(() => {
      setIsResetClick(false)
    }, 1000);
    IsGuidedTourOpen.current = false;
    showAllHover.current = false;
    setTourState(false);
    setSelectedButton(null);
    setShowCardContainer(false);
    setGlobalState("solutionsId", -1);
    setGlobalState("showDC", false);
    setGlobalState("playUCDirectly", false);
    setGlobalState("showUC", false);
    setGlobalState("IsTourOpen", false);
    setGlobalState("UCTourId", 0);
    setGlobalState("IsHomeButtonClick", true);
    setGlobalState("HoverId", 0);
    setGlobalState("HoverUseCaseId", 0);
    Howler.stop();
    // document.getElementById("close-btn").click();
    resetScreen();
    props.resetCamera();
  };

  const handleCascadingButtonClick = async (ucids, buttonId = "btnUseCasesEnabled") => {
    fetchAudio();
    if (selectedButton === buttonId) {
      // if same button clicked again, reset screen
      resetScreen();
      // return;
    }
    setShowCardContainer(true);
    setUseCaseMapping(false);
    handleButtonClick(buttonId);
    // setGlobalState("IsTourOpen", false);
    setGlobalState("IsBackgroundBlur", false);

    const baseAPIUrl = `${BaseAPI}use_case_list_segment`;
    const address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}&startID=${ucids}` : `../../${ApplicationDB}/use_case_list.json`;
    extraData[7] = [];
    try {
      const response = await fetch(address);
      const data = await response.json();
      extraData[7].push(data);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
    setSectionData(extraData[7][0].use_case_list);

    setUI_Element("popuptoolbar");
    setButtonType("Use_case");
    setGlobalState("HoverUseCaseId", 0);
    setGlobalState("IsButtonContainer", true);
    setGlobalState("playUCDirectly", false);
    setGlobalState("cascading", false);
  }
  const handleCascading2ButtonClick = async (ucids, buttonId = "btnfasolutions") => {
    if (selectedButton === buttonId) {
      // if same button clicked again, reset screen
      resetScreen();
      return;
    }
    setGlobalState("cascadingSols", false);
    setShowCardContainer(true);
    setUseCaseMapping(false);
    // handleButtonClick(buttonId);
    setGlobalState("useCase", 0);
    setGlobalState("HoverUseCaseId", 0);
    // setGlobalState("IsTourOpen", false);
    // setGlobalState("solutionsId", "1");
    setSelectedButton("btnfasolutions");
    // if(extraData[6][0] == null) {
    extraData[6] = [];
    const baseAPIUrl = `${BaseAPI}solutions`;
    const address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}&type=${ucids}` : `../../${ApplicationDB}/solutions.json`; //address for fetching sectiondata
    // CHANGES HERE
    try {
      const response = await fetch(address); //fetch section data files for specific config id
      const data = await response.json();
      extraData[6].push(data);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
    // }

    setSectionData(extraData[6][0].Solutions);
    setButtonType("D");
    setGlobalState("showUC", false);
    setUI_Element("popuptoolbar");
    setGlobalState("IsButtonContainer", true);
  }

  const extractNumericalPart = (value) => {
    if (typeof value === 'string') {
      const match = value.match(/^\d+/);
      return match ? parseInt(match[0], 10) : NaN;
    }
    return typeof value === 'number' ? value : NaN;
  };

  function extractStringPart(input) {
    const match = input.match(/[a-zA-Z]+/);
    return match ? match[0] : '';
  }

  const numToButtonId = new Map([
    ["0", "GuidedTourIntro"],
    ["1", "btnIntroduction"],
    ["2", "btnBusinessNeeds0"],
    ["3", "btnBusinessNeeds1"],
    ["4", "btnBusinessNeeds2"],
    ["5", "btnBusinessNeeds3"],
    ["6", "btnBusinessNeeds4"],
    ["7", "btnBusinessNeeds5"],
    ["8", "btnBusinessNeeds6"],
    ["9", "btnUseCasesEnabled0"],
    // ["10", "btnUseCasesEnabled201b"],
    ["10", "btnUseCasesEnabled202"],
    ["11", "btnUseCasesEnabled203b"],
    ["12", "btnUseCasesEnabled204"],
    // ["14", "btnUseCasesEnabled205"],
    ["13", "btnUseCasesEnabled101"],
    ["14", "btnUseCasesEnabled102"],
    ["15", "btnUseCasesEnabled103"],
    ["16", "btnUseCasesEnabled1"],
    ["17", "btnUseCasesEnabled2"],
    // ["20", "btnUseCasesEnabled3"],
    ["18", "btnUseCasesEnabled4"],
    ["19", "btnUseCasesEnabled5b"],
    // ["23", "btnUseCasesEnabled6"],
    // ["24", "btnUseCasesEnabled7"],
    ["20", "btnSalesChallenges0"],
    ["21", "btnSalesChallenges1"],
    ["22", "btnSalesChallenges2"],
    ["23", "btnSalesChallenges3"],
    ["24", "btnSalesChallenges4"],
    ["25", "btnSalesChallenges5"],
    ["26", "btnGuidingPrinciples0"],
    ["27", "btnGuidingPrinciples1"],
    ["28", "btnGuidingPrinciples2"],
    ["29", "btnGuidingPrinciples3"],
    ["30", "btnGuidingPrinciples4"],
    ["31", "btnGuidingPrinciples5"],
    ["32", "btnGuidingPrinciples6"],
    ["33", "btnfasolutions0"],
    ["34", "btnfasolutions1"],
    ["35", "btnfasolutions2"],
    ["36", "btnfasolutions3"],
    ["37", "Outro"]
  ]);

  var step = 0;
  const generateAllBoxesData = async () => {
    const promises = usecases.map(async (useCase) => {
        const id = useCase.id;
        const canvas = document.getElementsByClassName("main-canvas")[0];
        const pos = Vector3.Project(
            new Vector3(useCase.position.x, useCase.position.y, useCase.position.z),
            Matrix.Identity(), // world matrix
            scene.getTransformMatrix(), // transform matrix
            new Viewport(0, 0, canvas.width, canvas.height)
        );

        var baseAPIUrl;
        var address;
        baseAPIUrl = `${BaseAPI}use_case_list/`;
        address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/use_case_list.json`;

        const response = await fetch(address); // Fetch section data files for specific config id
        const data = await response.json();

        let short_label;
        data.use_case_list.forEach((uc) => {
            if (extractNumericalPart(id) == uc.use_case_id) {
                short_label = uc.short_label;
            }
        });

        return {
            id: id,
            label: short_label,
            x: pos.x,
            y: pos.y
        };
    });

    const resolvedData = await Promise.all(promises);
    return resolvedData;
};
  useEffect(() => {
    if (UcGuidedTour > 1 && IsGuidedTourOpen.current == true) {
      setTimeout(() => {
        step = UcGuidedTour;
        playGuidedTour();
      }, 300);
    }
  }, [UcGuidedTour, guidedTourOpen]);

  const savedata = async () => {
    const data = await generateAllBoxesData();
    allHoverData.current = data;
  }
  const playGuidedTour = async () => {
    if (!guidedTourOpen) guidedTourOpen = true;
    if (!isTourOpen) setIsTourOpen(true);
    if (step == 0){
      savedata();
    }

    if (step == 38) {
      IsGuidedTourOpen.current = false;
      setSelectedButton(null);
      setGlobalState("showDC", false);
      setGlobalState("IsBackgroundBlur", false);
      return;
    }
    
    else if (step == 2 || step == 20 || step == 26) {
      const button = numToButtonId.get(`${step}`);
      const id = Number(button.charAt(button.length - 1));
      if (step == 20) {
        props.resetCamera();
      }
      
      sethighlightCurrentCard(id);
      setGlobalState("highlightCurrentCard", id);
      setGlobalState("IsBackgroundBlur", false);
      setSelectedButton(extractStringPart(numToButtonId.get(`${step}`)));
      document.getElementById(extractStringPart(numToButtonId.get(`${step}`))).click();
    }
    else if ((step>=3 && step<=8) || (step>=21 && step<=25) || (step>=27 && step<=32)){
      const button = numToButtonId.get(`${step}`);
      const id = Number(button.charAt(button.length - 1));
      sethighlightCurrentCard(id);
      setGlobalState("highlightCurrentCard", id);
      setGlobalState("IsBackgroundBlur", true);
    }
    else if (numToButtonId.get(`${step}`) && numToButtonId.get(`${step}`).includes("btnfasolutions")) {
      const button = numToButtonId.get(`${step}`);
      const id = Number(button.charAt(button.length - 1));
      if (id == 0) setGlobalState("IsBackgroundBlur", false);
      document.getElementById("btnfasolutions").click();
      if (id != 0) {
        setTimeout(() => {
          try {
            document.getElementById(`fasolution${id}`).click();
          } catch (error) {
            document.getElementById("btnfasolutions").click();
            setTimeout(() => {
              document.getElementById(`fasolution${id}`).click();
            }, 400);
          }
        }, 1000);
      }
    }
    else if (numToButtonId.get(`${step}`) && numToButtonId.get(`${step}`).includes("btnUseCasesEnabled")) {
      const button = numToButtonId.get(`${step}`);
      const prefix = "btnUseCasesEnabled";
      var id = button.substring(prefix.length);
      if (!isNaN(id) && !isNaN(parseFloat(id))) {
        id = Number(id); // Convert to number if it's purely numerical
      }
      var num_id = extractNumericalPart(id);
      if (id != 0) {

        let useCase = null;
        usecases.forEach((uc) => {
          if ((uc.id) == id) {
            useCase = uc;
          }
        });
        // setIsBoxVisible(true);
        const canvas = document.getElementsByClassName("main-canvas")[0];
        const movingCamera = scene.getCameraByName('camera-3');
        const securityCamera = scene.getCameraByName(`security-camera-${id}`);

        const finalTarget = new Vector3(useCase.position.x, useCase.position.y, useCase.position.z);
        const finalPosition = new Vector3(useCase.cameraPosition.x, useCase.cameraPosition.y, useCase.cameraPosition.z);
        movingCamera.position.copyFrom(scene.activeCamera.position);
        movingCamera.setTarget(scene.activeCamera.target.clone());
        scene.activeCamera = movingCamera;

        const func = async (movingCamera, securityCamera, canvas) => {
          movingCamera.lockedTarget = null;
          securityCamera.setTarget(finalTarget);
          securityCamera.setPosition(finalPosition);
          // securityCamera.lowerRadiusLimit = 40;
          // securityCamera.upperRadiusLimit = 70;
          scene.activeCamera = securityCamera;
          securityCamera.detachControl(canvas);
          // securityCamera.attachControl(canvas, true);

          // setCurrentZoomedSection(0);

          const pos = Vector3.Project(
            new Vector3(useCase.position.x, useCase.position.y, useCase.position.z),
            Matrix.Identity(), // world matrix
            scene.getTransformMatrix(), // transform matrix
            new Viewport(0, 0, canvas.width, canvas.height)
          );

          var baseAPIUrl;
          var address;
          baseAPIUrl = `${BaseAPI}use_case_list/`;
          address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/use_case_list.json`;

          const response = await fetch(address); //fetch section data files for specific config id
          const data = await response.json();
          var short_label;
          data.use_case_list.forEach((uc) => {
            if (extractNumericalPart(id) == uc.use_case_id) {
              short_label = uc.short_label;
            }
          });
          setUCTourId(num_id);
          setHoverId(id);
          setHoverLabel(short_label);
          setGlobalState("clientXPosition1", pos.x);
          setGlobalState("clientYPosition1", pos.y);
          setGlobalState("UCTourId", num_id);
        };

        await rotateToTarget(scene, finalTarget, movingCamera, 1.2, linearAnimation, scene, finalTarget, movingCamera.position, finalPosition, 1, func, movingCamera, securityCamera, canvas);
      }
    }
    if (step == 9) {
      // const data = await generateAllBoxesData();
      // allHoverData.current = data;
      document.getElementById("btnUseCasesEnabled").click();
      showAllHover.current = true;
    }
    const sound = new Howl({
      src: !packageApp ? `${assetsLocation}${ApplicationDB}/audio/gt/${step}.mp3` : `../../${ApplicationDB}/audio/gt/${step}.mp3`,

      preload: 'metadata', 
      loop: false
    });
    setCurrentSound(sound);
    sound.play();

    sound.on("end", async function () {
      if (step == 9 )
      {
        showAllHover.current = false;
        setGlobalState("IsTourOpen", true);
      }
      setCurrentSound(null);
      setAnchorEl(null);
      if (numToButtonId.get(`${step + 1}`) && numToButtonId.get(`${step + 1}`).includes("btnIntroduction")) {
        setTimeout(() => {
          document.getElementById("btnIntroduction").click();
          setGlobalState("IsBackgroundBlur", true);
          setGlobalState("UcGuidedTour", 1);
        }, 300);
      }
      else if (step == 8) {
        document.getElementById("btnBusinessNeeds").click();
        ++step;
        playGuidedTour();
      }
      else if (numToButtonId.get(`${step}`) && numToButtonId.get(`${step}`).includes("btnUseCasesEnabled")) {
        setGlobalState("HoverId", 0);
        setHoverLabel("");
        setGlobalState("UCTourId", 0);
        ++step;
        playGuidedTour();
      }
      else if (step == 37) {
        props.resetCamera();
        IsGuidedTourOpen.current = false;
        setGlobalState("showDC", false);
        setSelectedButton("");
        setGlobalState("isTourOpen", false);
        step = 0;
        return;
      }
      else {
        ++step;
        playGuidedTour();
      }
    });

  };

  return (
    <div>
      {showAllHover.current
        ? allHoverData.current.map((item) => (
            <div style={{ top: item.y, left: item.x, position: 'absolute' }} className="hot-spot-subMenu">
                <div>
                    <div className="hover-label-text">{item.label}</div>
                    <hr style={{ marginTop: "5%" }} className="card-divider"></hr>
                </div>
                <div className="button-group">
                <div className="learn-more" onClick={() => handlePlayStory()}>Learn More</div>
                </div>
            </div>
        ))
      :<CSSTransition
        in={(extractNumericalPart(HoverId) > 0)}
        timeout={50} // Duration of the animation in milliseconds
        classNames="animationHover" // Your CSS class for animations
        unmountOnExit
        mountOnEnter
      >
        <div style={{ top: clientYPosition1, left: clientXPosition1 }} className="hot-spot-subMenu">
          <div>
            <div className="hover-label-text">{HoverLabel}</div>
            <hr style={{ marginTop: "5%" }} className="card-divider"></hr>
          </div>
          <div className="button-group" >
            {/* {(isTourOpen || useCase !== 0) ? "" :
              scene.activeCamera.name.includes("security") == false ?
                <div className="zoom-in" onClick={() => setGlobalState("currentZoomedSection", HoverId)}>Zoom-in</div>
                :
                <div className="zoom-in" onClick={() => props.resetCamera()}>Zoom-out</div>
            } */}
            <div className="learn-more" onClick={() => handlePlayStory()}>{useCase !== 0 ? "End Story" : "Learn More"}</div>

          </div>
        </div>
      </CSSTransition>
      }
      <div style={{ display: 'flex' }}>

        <div className={`${MainMenuIsButtons ? "toolbar reset-toolbar" : "plain-reset-toolbar"} `} >
          <ToolbarButton
            forwardRef={buttonRef}
            reset = {true}
            id="reset"
            buttonId="reset"
            selectedButton={selectedButton}
            active={"reset" === selectedButton}
            buttonName="Reset the Experience"
            handleButtonClick={handleResetButtonClick}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >

            <svg width="4vh" height="4vh" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_bd_38_67)">
                <rect x="6" y="3" width="52" height="52" rx="26" fill="#D8D8D8" />
              </g>
              <path d="M31.6279 40C28.3821 40 25.6323 38.8867 23.3787 36.6601C21.1262 34.4324 20 31.714 20 28.5049C20 25.2959 21.1262 22.5764 23.3787 20.3465C25.6323 18.1155 28.3821 17 31.6279 17C33.6024 17 35.4507 17.4638 37.1728 18.3915C38.8959 19.3192 40.2846 20.6143 41.3389 22.2769V17H43V25.5921H34.3123V23.9493H40.4585C39.5936 22.3119 38.3765 21.0179 36.8073 20.0672C35.2403 19.1176 33.5138 18.6429 31.6279 18.6429C28.8594 18.6429 26.5061 19.6012 24.5681 21.5179C22.6301 23.4345 21.6611 25.7619 21.6611 28.5C21.6611 31.2381 22.6301 33.5655 24.5681 35.4821C26.5061 37.3988 28.8594 38.3571 31.6279 38.3571C33.7597 38.3571 35.6838 37.7548 37.4003 36.55C39.1168 35.3452 40.3211 33.7571 41.0133 31.7857H42.7757C42.0437 34.2456 40.6523 36.2296 38.6013 37.7378C36.5504 39.2459 34.2259 40 31.6279 40Z" fill="black" />
              <defs>
                <filter id="filter0_bd_38_67" x="0" y="-3" width="64" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="3" />
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_38_67" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="3" />
                  <feGaussianBlur stdDeviation="3" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                  <feBlend mode="normal" in2="effect1_backgroundBlur_38_67" result="effect2_dropShadow_38_67" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_38_67" result="shape" />
                </filter>
              </defs>
            </svg>

            Reset
          </ToolbarButton>

          {/* {MainMenuIsButtons ? "" : <div className='plain-reset-divider'></div>}
          
          <ToolbarButton
            buttonId="btnWelcomeCards"
            selectedButton={selectedButton}
            reset = {true}
            active={"btnWelcomeCards" === selectedButton}
            buttonName="Welcome Screen"
            handleButtonClick={async (buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                return;
              }
              setCount(0);
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              // setGlobalState("useCase", 1);
              // setGlobalState("IsTourOpen", false);
              // handleUseCaseButtonClick("btnMyHostelStory");
              setGlobalState("IsButtonContainer", false);
              setGlobalState("IsHomeButtonClick", false);
              setGlobalState("ApplicationDB", ApplicationDB);
              setGlobalState("playUCDirectly", true);
              setGlobalState("IsBackgroundBlur", true);
              if (isTourOpen) {
                props.resetCamera();
              }
              Howler.stop();
              setSelectedButton(buttonId);
              setGlobalState("IsButtonContainer", false);
              setUI_Element("welcome");
              setShowCardContainer(true);
              return;
            }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            How to Use
          </ToolbarButton> */}
        </div>

        <div
          // style={{ justifyContent: MainMenuIsButtons ? "center" : "end" }}
          className={`${MainMenuIsButtons ? "toolbar" : "plain-toolbar"} `}
        >
          <ToolbarButton  // Guided Tour button
            buttonId="btnGuidedTour"
            reset = {false}
            selectedButton={selectedButton}
            active={"btnGuidedTour" === selectedButton}
            buttonName="Guided Tour"
            handleButtonClick={async (buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                return;
              }
              if (isTourOpen == false){
                setIsTourOpen(true);
              }
              else if (isTourOpen == true){
                handleResetButtonClick();
                return;
              }
              guidedTourOpen = true;
              setSelectedButton("btnGuidedTour");

              handleButtonClick(buttonId);
              IsGuidedTourOpen.current = true;
              setGlobalState("IsBackgroundBlur", false);
              setGlobalState("useCase", 0);
              setGlobalState("HoverUseCaseId", 0);
              setGlobalState("playUCDirectly", false);
              step = 0;
              playGuidedTour();
            }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            {isTourOpen ? "End Tour" : "Guided Tour"}
          </ToolbarButton>
          {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
          <ToolbarButton
            buttonId="btnIntroduction"
            reset = {false}
            selectedButton={selectedButton}
            active={"btnIntroduction" === selectedButton}
            buttonName="Introduction"
            handleButtonClick={async (buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                setUI_Element(null);
                setGlobalState("playUCDirectly", false);
                return;
              }
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              // setGlobalState("useCase", 1);
              // setGlobalState("IsTourOpen", false);
              // handleUseCaseButtonClick("btnMyHostelStory");
              setGlobalState("IsButtonContainer", false);
              setGlobalState("IsHomeButtonClick", false);
              setGlobalState("ApplicationDB", ApplicationDB);
              setGlobalState("playUCDirectly", true);
              // setGlobalState("IsTourOpen", false);
              Howler.stop();
              setSelectedButton(buttonId);
              var intronumber = '901';
              if (intro && intro != "") {
                intronumber = intro;
              }
              try {
                const apiurl = !packageApp ? `${BaseAPI}use_case_stories_emotion/${intronumber}?db=${ApplicationDB}` : `../../${ApplicationDB}/use_case_stories_emotion/${intronumber}.json`;
                if (extraData[9].length == 0) {
                  const response = await fetch(apiurl);
                  const data = await response.json();
                  extraData[9][0] = data;
                }
              } catch (error) {
                console.error("Error fetching data:", error);
              }
              setSectionData(extraData[9][0]);
              setButtonType("Use_case");
              setGlobalState("IsButtonContainer", false);
              setGlobalState("IsBackgroundBlur", true);
              setUI_Element("popuptoolbar");
              setShowCardContainer(true);
              setGlobalState("HoverUseCaseId", Number(intronumber));
              return;
            }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            Core Proposition
          </ToolbarButton>



          {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
          <ToolbarButton // Guided Tour button
            buttonId="btnBusinessNeeds" //1
            selectedButton={selectedButton}
            reset = {false}
            active={"btnBusinessNeeds" === selectedButton}
            buttonName="Business Needs"
            handleButtonClick={async (buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                return;
              }
              document.getElementById("btnUseCasesEnabled").click();
              if(guidedTourOpen){
                setGlobalState("IsTourOpen", true);
              }else{
                setGlobalState("IsTourOpen", false);
              }
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              setGlobalState("IsBackgroundBlur", true);
              setGlobalState("useCase", 0);
              setGlobalState("HoverId", 0);
              setGlobalState("HoverUseCaseId", 0);
              if(guidedTourOpen){
                setGlobalState("IsTourOpen", true);
              }

              if (extraData[0][0] == null) {
                const baseAPIUrl = `${BaseAPI}section/`;
                const address = !packageApp ? `${baseAPIUrl + "1"}?db=${ApplicationDB}` : `../../${ApplicationDB}/section/1.json`;
                try {
                  const response = await fetch(address);
                  const data = await response.json();
                  extraData[0].push(data);
                } catch (error) {
                  // console.error("Error fetching data:", error);
                }
              }

              setSectionData(extraData[0][0].SectionData);
              setUseCaseMapping(true);
              setLinkType("OU");
              setUI_Element("");
              setUI_Element("cards");
            }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            Business Outcomes
          </ToolbarButton>
          {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
          <ToolbarButton // Use Case Story Button
            buttonId="btnUseCasesEnabled" //8
            selectedButton={selectedButton}
            reset = {false}
            active={"btnUseCasesEnabled" === selectedButton}
            buttonName="Use Cases Enabled"
            handleButtonClick={async (buttonId, buttonName) => {
              fetchAudio();
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                setUI_Element(null);
                return;
              }
              setShowCardContainer(true);
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              if(guidedTourOpen){
                setGlobalState("IsTourOpen", true);
              }else{
                setGlobalState("IsTourOpen", false);
              }
              setGlobalState("IsBackgroundBlur", false);

              if (extraData[7][0] == null) {
                extraData[7][0] = null;
                const baseAPIUrl = `${BaseAPI}use_case_list`;
                const address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/use_case_list.json`;
                try {
                  const response = await fetch(address);
                  const data = await response.json();
                  extraData[7] = [[], [], []];
                  data.use_case_list.forEach((element) => {
                    if (element.use_case_id < 100) extraData[7][0].push(element);
                    else if (element.use_case_id < 200) extraData[7][1].push(element);
                    else if (element.use_case_id < 300) extraData[7][2].push(element);
                  });
                } catch (error) {
                  // console.error("Error fetching data:", error);
                }
              }
              setSectionData(extraData[7]);
              if(guidedTourOpen){
                setGlobalState("IsTourOpen", true);
              }
              setUI_Element("cascading");
              setButtonType("Use_case");
              setGlobalState("HoverUseCaseId", 0);
              setGlobalState("IsButtonContainer", true);
              setGlobalState("playUCDirectly", false);
            }}
            handleMenuClick={handleClick}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            Use Cases


          </ToolbarButton>
          {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
          <ToolbarButton
            buttonId="btnSalesChallenges"
            reset = {false}
            active={"btnSalesChallenges" === selectedButton}
            selectedButton={selectedButton}
            buttonName="Sales Challenges"
            handleButtonClick={async (buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                return;
              }
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              setGlobalState("IsBackgroundBlur", true);
              setGlobalState("useCase", 0);
              setGlobalState("HoverId", 0);
              setGlobalState("HoverUseCaseId", 0);
              setGlobalState("playUCDirectly", false);
              if(guidedTourOpen){
                setGlobalState("IsTourOpen", true);
              }else{
                setGlobalState("IsTourOpen", false);
              }
              // setGlobalState("IsTourOpen", false);

              if (extraData[1][0] == null) {
                const baseAPIUrl = `${BaseAPI}section/`;
                const address = !packageApp ? `${baseAPIUrl + "2"}?db=${ApplicationDB}` : `../../${ApplicationDB}/section/2.json`; //address for fetching sectiondata
                // CHANGES HERE
                try {
                  const response = await fetch(address); //fetch section data files for specific config id
                  const data = await response.json();
                  extraData[1].push(data);
                } catch (error) {
                }
              }

              setSectionData(extraData[1][0].SectionData);
              setUseCaseMapping(true);
              setLinkType("CU");
              setUI_Element("cards");
            }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            Challenges
          </ToolbarButton>
          {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
          <ToolbarButton // DVS button
            buttonId="btnGuidingPrinciples" //4
            reset = {false}
            active={"btnGuidingPrinciples" === selectedButton}
            selectedButton={selectedButton}
            buttonName="Guiding Principles"
            handleButtonClick={async (buttonId, buttonName) => {
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                return;
              }
              setUseCaseMapping(false);
              handleButtonClick(buttonId);
              setGlobalState("IsBackgroundBlur", true);
              setGlobalState("useCase", 0);
              setGlobalState("HoverId", 0);
              setGlobalState("HoverUseCaseId", 0);
              if(guidedTourOpen){
                setGlobalState("IsTourOpen", true);
              }else{
                setGlobalState("IsTourOpen", false);
              }
              // setGlobalState("IsTourOpen", false);

              if (extraData[3][0] == null) {
                const baseAPIUrl = `${BaseAPI}section/`;
                const address = !packageApp ? `${baseAPIUrl + "4"}?db=${ApplicationDB}` : `../../${ApplicationDB}/section/4.json`; //address for fetching sectiondata
                // CHANGES HERE
                try {
                  const response = await fetch(address); //fetch section data files for specific config id
                  const data = await response.json();
                  extraData[3].push(data);
                } catch (error) {
                }
              }

              setSectionData(extraData[3][0].SectionData);

              setUI_Element("");
              setUI_Element("cards");
            }}
            handleMenuClick={() => { }}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            Solution Principles
          </ToolbarButton>

          {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
          <ToolbarButton
            buttonId="btnfasolutions"
            reset = {false}
            active={"btnfasolutions" === selectedButton}
            selectedButton={selectedButton}
            buttonName="FieldAssist Solutions"
            handleButtonClick={async (buttonId, buttonName) => {
              if (!playAndPause) {
                setGlobalState("playAndPause", true)
              }
              if (selectedButton === buttonId) {
                // if same button clicked again, reset screen
                resetScreen();
                setUI_Element(null);
                return;
              }
              setShowCardContainer(true);
              setUseCaseMapping(false);
              // handleButtonClick(buttonId);
              setGlobalState("useCase", 0);
              setGlobalState("HoverId", 0);
              setGlobalState("HoverUseCaseId", 0);
              if(guidedTourOpen){
                setGlobalState("IsTourOpen", true);
              }else{
                setGlobalState("IsTourOpen", false);
              }
              // setGlobalState("solutionsId", "1");
              setSelectedButton("btnfasolutions");
              if (extraData[6][0] == null) {
                const baseAPIUrl = `${BaseAPI}solutions`;
                const address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/solutions.json`; //address for fetching sectiondata
                // CHANGES HERE
                try {
                  const response = await fetch(address); //fetch section data files for specific config id
                  const data = await response.json();
                  extraData[6] = [[], [], []];
                  data.Solutions.forEach((element) => {
                    if (element.soln_type == "P") extraData[6][0].push(element);
                    else if (element.soln_type == "F") extraData[6][1].push(element);
                    else if (element.soln_type == "S") extraData[6][2].push(element);
                  });
                } catch (error) {
                }
              }

              setSectionData(extraData[6]);
              setButtonType("D");
              setGlobalState("showUC", false);
              setUI_Element("cascading");
              setGlobalState("IsButtonContainer", true);
            }}
            handleMenuClick={handleClick}
            MainMenuIsButtons={MainMenuIsButtons}
          >
            3I Solutions
          </ToolbarButton>
        </div>
      </div>


      {/* Display elements if clicked */}

      {/* Cards or DVS tab */}
      <MenuDispensor
        buttonType={buttonType}
        sectionData={sectionData}
        ui_element={ui_Element}
        buttonId={selectedButton}
        useCaseMapping={useCaseMapping}
        handleMenuItemClick={handleMenuItemClick}
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        alignItems={alignItems}
        showCardContainer={showCardContainer}
        WelcomeData={WelcomeData}
        WelcomeData1={WelcomeData1}
        count={count}
        handleNext={handleNext}
        handleSkip={handleSkip}
        link_type={linkType}
        handlePlayStory={handlePlayStory}
        IsGuidedTourOpen={IsGuidedTourOpen.current}
      />

      {/* UseCases/Guided Tour tab */}
      {/* {showTour && fetched && <UseCase steps={stepData} useCaseID={5} />} */}
    </div>
  );
};

export default MainPage;