import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../css/ReactResponsiveCarousel.css";
import { setGlobalState, useGlobalState } from "../state";
import { ApplicationDB, assetsLocation, packageApp, TVScreenSolutions } from "../../assets/assetsLocation";

const Card = ({ item, setCurrentItem }) => {
  const [applicationDB] = useGlobalState("ApplicationDB");
  const [isMounted, setIsMounted] = useState(false); // State to control enter/exit transitions

  useEffect(() => {
    setIsMounted(true); // Trigger enter transition
    return () => setIsMounted(false); // Trigger exit transition on unmount
  }, []);

  const imgfullscreen = () => {
    const imageUrl = !packageApp
      ? `${assetsLocation}${ApplicationDB}/graphics/${item.graphic}`
      : `../../${ApplicationDB}/graphics/${item.graphic}`;

    // Open the image in a new tab
    window.open(imageUrl, '_blank');
  };

  const httpfullscreen = () => {
    window.open(`${item.graphic}`, '_blank');
  };

  const isImage = (url) => /\.(jpg|JPG|jpeg|png|webp|avif|gif|svg)$/.test(url);
  const isVideo = (file) => /\.(mp4|webm|ogg)$/i.test(file);
  const isHTML = (file) => /\.html$/i.test(file);
  const isHttp = (url) => /^http/.test(url);

  return (
    <div className={`CardContainer ${isMounted ? "enter" : "exit"}`}>
      {isHttp(item.graphic) ? (
        <div>
          <iframe
            src={`${item.graphic}`}
            style={{ width: "100%", height: "500px", border: "none" }}
            title="HTML Content"
            className={isMounted ? "media-enter" : "media-exit"}
          />
          <button onClick={httpfullscreen} className="fullscreen-button">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_93_31"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="25"
                height="25"
              >
                <rect x="0.678864" y="0.910553" width="24" height="24" fill="#005486" />
              </mask>
              <g mask="url(#mask0_93_31)">
                <path
                  d="M3 21V16H5V19H8V21H3ZM16 21V19H19V16H21V21H16ZM3 8V3H8V5H5V8H3ZM19 8V5H16V3H21V8H19Z"
                  fill="#005486"
                />
              </g>
            </svg>
          </button>

        </div>
      )
       : isImage(item.graphic) ? (
        <div>
          <img
            alt="test"
            src={
              !packageApp
                ? `${assetsLocation}${ApplicationDB}/graphics/${item.graphic}`
                : `../../${ApplicationDB}/graphics/${item.graphic}`
            }
            className={isMounted ? "media-enter" : "media-exit"}
          />
          <button onClick={imgfullscreen} className="fullscreen-button">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_93_31"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="25"
                height="25"
              >
                <rect x="0.678864" y="0.910553" width="24" height="24" fill="#005486" />
              </mask>
              <g mask="url(#mask0_93_31)">
                <path
                  d="M3 21V16H5V19H8V21H3ZM16 21V19H19V16H21V21H16ZM3 8V3H8V5H5V8H3ZM19 8V5H16V3H21V8H19Z"
                  fill="#005486"
                />
              </g>
            </svg>
          </button>
        </div>
      ) : isVideo(item.graphic) ? (
        <div>
          <video
            autoPlay
            loop
            preload="metadata"
            id="mf-video"
            muted
            controls
            style={{ width: "100%", verticalAlign: "bottom" }}
            className={isMounted ? "media-enter" : "media-exit"}
          >
            <source
              src={
                !packageApp
                  ? `${assetsLocation}${ApplicationDB}/graphics/${item.graphic}`
                  : `../../${ApplicationDB}/graphics/${item.graphic}`
              }
              type="video/mp4"
            />
          </video>
        </div>
      ) : isHTML(item.graphic) ? (
        <div>
          <iframe
            src={
              !packageApp
                ? `${assetsLocation}${ApplicationDB}/graphics/${item.graphic}`
                : `../../${ApplicationDB}/graphics/${item.graphic}`
            }
            style={{ width: "100%", height: "500px", border: "none" }}
            title="HTML Content"
            className={isMounted ? "media-enter" : "media-exit"}
          />
        </div>
      ) : (
        <div>Unsupported file format</div>
      )}
    </div>
  );
};


const ReactResponsiveCarousel = ({ solutionGraphicsData }) => {
  const [isAutoPlay] = useGlobalState("IsAutoPlay");
  const [applicationDB] = useGlobalState("ApplicationDB");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [imagePosition, setImagePosition] = useState(0);
  const [isImageFullScreen, setIsImageFullScreen] = useGlobalState("isImageFullScreen");
  const [currentItem, setCurrentItem] = useState(null); // Added state to track current item
  const [currentSoundIdx, setCurrentSoundIdx] = useState();

  document.onkeydown = function (evt) {
    if (evt.keyCode === 27) {
      setIsFullScreen(false);
    }
  };



  const isGraphicSame = solutionGraphicsData.every(item => item.graphic === solutionGraphicsData[0].graphic);

  return (
    <>
      {isFullScreen && currentItem && (
        <img
          className="full-screen-img"
          src={!packageApp ? `${assetsLocation}${applicationDB}/graphics/${currentItem.graphic}` : `../../${applicationDB}/graphics/${currentItem.graphic}`}
        />
      )}
      {isImageFullScreen && currentItem && (
        <div className="background">
          <div className="fullscreen">
            <img
              alt="image missing"
              src={!packageApp ? `${assetsLocation}${applicationDB}/graphics/${currentItem.graphic}` : `../../${applicationDB}/graphics/${currentItem.graphic}`}
            />
          </div>
        </div>
      )}
      <div className={TVScreenSolutions ? "CarouselContainer" : ""}>
        {isGraphicSame ? (
          <Card item={solutionGraphicsData[0]} setCurrentItem={setCurrentItem} />
        ) : (
          <Carousel
            width="auto"
            dynamicHeight={false}
            autoPlay={false}
            stopOnHover
            swipeable
            infiniteLoop
            showIndicators={false}
            emulateTouch
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            selectedItem={0}
            interval={2000}
            onChange={(e) => setImagePosition(e)}
          >
            {solutionGraphicsData.map((item, index) => (
              <Card key={item.order_seq} item={item} setCurrentItem={setCurrentItem} />
            ))}
          </Carousel>
        )}
      </div>
    </>
  );
};

export default ReactResponsiveCarousel;
