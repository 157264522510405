import React, { useEffect, useState } from "react";
import "../css/DataCard.css";
import ReactResponsiveCarousel from "./ReactResponsiveCarousel";
import { QRCodeCanvas } from "qrcode.react";
import { setGlobalState, useGlobalState } from "../state";
import { ApplicationDB, BaseAPI, carouselType, packageApp } from "../../assets/assetsLocation";
import ReactAwesomeCarousel from "./ReactAwesomeCarousel";
import { CSSTransition } from "react-transition-group";
import InitializeGoogleAnalytics from "./googleanalytics.tsx";
import TrackGoogleAnalyticsEvent from "./googleanalytics.tsx";

// Moved DynamicCarousel outside the DataCard component
const DynamicCarousel = ({ solutionGraphicsData, carouselType }) => {  
	switch (carouselType) {
	  case "1":
		return (
			<ReactResponsiveCarousel
			  solutionGraphicsData={solutionGraphicsData}
			/>
		);
	  case "2":
		return (
			<ReactAwesomeCarousel
			  solutionGraphicsData={solutionGraphicsData}
			/>
		);
	  default:
		return null;
	}
  };
  
  const DataCard = ({ data, onClose, handlePreButton, handleNexButton, showCardContainer }) => {
	useEffect(() => {
	  InitializeGoogleAnalytics();
	  TrackGoogleAnalyticsEvent(
		'data card',
		ApplicationDB,
		data
	  );
	  if (data && data.short_label) {
		const dataForAI = {};
		dataForAI[data.short_label] = data.long_desc;
		setGlobalState("currentContext", dataForAI);
	  }
	}, [data]);
  
	const [solutionGraphicsData, setSolutionGraphicsData] = useState();
	const [isHoverRight, setIsHoverRight] = useState();
	const [isHoverLeft, setIsHoverLeft] = useState();
	const [applicationDB, setApplicationDB] = useGlobalState("ApplicationDB");
	const [numGraphics, setNumGraphics] = useState(0);
	const [currentSlide, setCurrentSlide] = useGlobalState("currentSlide"); 

	useEffect(() => {
	  setShowDiv(showCardContainer);
	}, [showCardContainer]);
  
	useEffect(() => {
		if (!solutionGraphicsData) {return};
		if (currentSlide > solutionGraphicsData.length) {setCurrentSlide(1)};
		if (currentSlide < 1) {setCurrentSlide(solutionGraphicsData.length)};
	  }, [currentSlide]);


	useEffect(() => {
	  if (data?.id) {
		setSolutionGraphicsData();
		fatchSolutionGraphicsData();
	  }
	}, [data?.id]);
	
	const fatchSolutionGraphicsData = async () => {
	  try {
		const baseAPIUrl = !packageApp ? `${BaseAPI}solution_graphics?id=${data.id}&db=${applicationDB}` : `../../${applicationDB}/solution_graphics/${data.id}.json`;
		const address = baseAPIUrl; //address for fetching sectiondata
		const response = await fetch(address); //fetch section data files for specific config id
		const responseData = await response.json();
		let SolutionGraphics = responseData.SolutionGraphics.filter((item) => {
		  if (item.solution_id == data.id) {
			return item;
		  }
		});
		setNumGraphics(SolutionGraphics.length);
		setSolutionGraphicsData(SolutionGraphics);
		setCurrentSlide(1);
	  } catch (error) {
		console.error("Error fetching data:", error);
	  }
	};
  
	const [showDiv, setShowDiv] = useState(false);
  
	return data ? (
	  <CSSTransition
		in={showDiv}
		timeout={225} // Duration of the animation in milliseconds
		classNames="animationMenu" // Your CSS class for animations
		unmountOnExit
		mountOnEnter
	  >
		<div className="rectangle">  
		  <div className="LeftArrow blink">
			{numGraphics > 1 && carouselType == "1" ?
			  <svg className={isHoverLeft ? "nex-prev-hover" : "nex-prev"} onClick={() => handlePreButton()} onMouseEnter={() => setIsHoverLeft(true)} onMouseLeave={() => setIsHoverLeft(false)} width="6.6vh" height="6.6vh" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="56" height="56" rx="28" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 59 59)" />
				<rect x="1.5" y="1.5" width="59" height="59" rx="29.5" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 62 62)" stroke="#0B37A4" stroke-opacity="0.6" stroke-width="3" />
				<path d="M34.2 24.6001L27.8 31.0001L34.2 37.4001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<defs>
				  <linearGradient id="paint0_linear_35_413" x1="4.4" y1="14.4" x2="48" y2="20.8" gradientUnits="userSpaceOnUse">
					<stop stop-color="#040C32" />
					<stop offset="1" stop-color="#040C38" stop-opacity="0.82" />
				  </linearGradient>
				</defs>
			  </svg>
			  : ""}
		  </div>
		  <div className="left-section">
			<h2 className="heading">{data.short_label}</h2>
			<svg onClick={onClose} className="close-button" width="3vh" height="3vh" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="30" height="30" rx="15" fill="#1033A4" fill-opacity="0.4" />
                      <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="white" stroke-opacity="0.4" />
                      <path d="M21.5075 8.37791C21.415 8.28521 21.3051 8.21166 21.1841 8.16148C21.0631 8.1113 20.9335 8.08547 20.8025 8.08547C20.6715 8.08547 20.5418 8.1113 20.4209 8.16148C20.2999 8.21166 20.19 8.28521 20.0975 8.37791L15.2075 13.2579L10.3175 8.36791C10.2249 8.27533 10.115 8.20189 9.99404 8.15179C9.87308 8.10168 9.74343 8.07589 9.6125 8.07589C9.48157 8.07589 9.35192 8.10168 9.23095 8.15179C9.10999 8.20189 9.00008 8.27533 8.9075 8.36791C8.81492 8.4605 8.74148 8.57041 8.69137 8.69137C8.64127 8.81233 8.61548 8.94198 8.61548 9.07291C8.61548 9.20384 8.64127 9.33349 8.69137 9.45446C8.74148 9.57542 8.81492 9.68533 8.9075 9.77791L13.7975 14.6679L8.9075 19.5579C8.81492 19.6505 8.74148 19.7604 8.69137 19.8814C8.64127 20.0023 8.61548 20.132 8.61548 20.2629C8.61548 20.3938 8.64127 20.5235 8.69137 20.6445C8.74148 20.7654 8.81492 20.8753 8.9075 20.9679C9.00008 21.0605 9.10999 21.1339 9.23095 21.184C9.35192 21.2341 9.48157 21.2599 9.6125 21.2599C9.74343 21.2599 9.87308 21.2341 9.99404 21.184C10.115 21.1339 10.2249 21.0605 10.3175 20.9679L15.2075 16.0779L20.0975 20.9679C20.1901 21.0605 20.3 21.1339 20.421 21.184C20.5419 21.2341 20.6716 21.2599 20.8025 21.2599C20.9334 21.2599 21.0631 21.2341 21.184 21.184C21.305 21.1339 21.4149 21.0605 21.5075 20.9679C21.6001 20.8753 21.6735 20.7654 21.7236 20.6445C21.7737 20.5235 21.7995 20.3938 21.7995 20.2629C21.7995 20.132 21.7737 20.0023 21.7236 19.8814C21.6735 19.7604 21.6001 19.6505 21.5075 19.5579L16.6175 14.6679L21.5075 9.77791C21.8875 9.39791 21.8875 8.75791 21.5075 8.37791Z" fill="white" fill-opacity="0.8" />
                    </svg> 
			<hr className="divider" style={{ marginTop: '6%' }} />
			<p className="content" dangerouslySetInnerHTML={{ __html: data?.long_desc }}/>

				<div className="left-image-container" style={{ display: 'flex', alignItems: 'center' }}>
					<QRCodeCanvas
						className="left-image"
						id="qrCode"
						value={data.details_url}
						bgColor={"#fff"}
						level={"H"}
						style={{ width: '1vw', height: 'auto' }}
					/>
					<p className="scan-content"><a href={data.details_url} target="_blank">Scan QR or click <br /> for more details</a>
					</p>

				</div>



			<div className="slide-count"> Showing Visual: {solutionGraphicsData ? `${currentSlide} of ${solutionGraphicsData.length}` : ""} </div>
	
		  </div>
		  <div className="right-section">
			<div className="image-container">
			  {solutionGraphicsData && solutionGraphicsData.length > 0 ?
				<DynamicCarousel solutionGraphicsData={solutionGraphicsData} carouselType={carouselType} />
				: ''}
			</div>
		  </div>
		  <div className="RightArrow blink">
			{numGraphics > 1 && carouselType == "1" ?
			  <svg className={isHoverRight ? "nex-prev-hover" : "nex-prev"} onMouseEnter={() => setIsHoverRight(true)} onMouseLeave={() => setIsHoverRight(false)} onClick={() => handleNexButton()} width="6.6vh" height="6.6vh" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="5" y="61" width="56" height="56" rx="28" transform="rotate(-90 5 61)" />
				<rect x="2.5" y="63.5" width="61" height="61" rx="30.5" transform="rotate(-90 2.5 63.5)" stroke="#0B37A4" stroke-opacity="0.4" stroke-width="5" />
				<path d="M29.8 26.6001L36.2 33.0001L29.8 39.4001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<defs>
				  <linearGradient id="paint0_linear_35_410" x1="9.4" y1="75.4" x2="53" y2="81.8" gradientUnits="userSpaceOnUse">
					<stop stop-color="#0F2266" />
					<stop offset="1" stop-color="#040C38" stop-opacity="0.72" />
				  </linearGradient>
				</defs>
			  </svg>
			  : ""}
		  </div>
		  <div className="close-button" onClick={onClose}>X</div>
		</div>
	  </CSSTransition>
	) : (
	  <></>
	);
  };
  

export default DataCard;
