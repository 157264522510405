import React, { useState, useEffect, useRef } from "react";
import "../css/UseCase.css";
import { Howl, Howler } from "howler";
import { setGlobalState, useGlobalState } from "../state";
import { setIsUseCaseStoryOpen, getIsUseCaseStoryOpen } from '../state/globalRef.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactResponsiveCarousel from "./ReactResponsiveCarousel";
import Close from "../assets/Group 101.png";
import Button from "./Button";
import {
  Vector3,
  Viewport,
  Matrix,
  RandomNumberBlock
} from '@babylonjs/core';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { BaseAPI, ApplicationDB, assetsLocation, carouselType, packageApp } from "../../assets/assetsLocation";
import ReactAwesomeCarousel from "./ReactAwesomeCarousel";
import InitializeGoogleAnalytics from "./googleanalytics.tsx";
import { TrackGoogleAnalyticsEvent } from "./googleanalytics.tsx";
import { changeLightEmotion, resetLights } from "./LightUtils.jsx"
// import usecases from "../../data/usecases.json"
import { fetchUsecases } from './fetchusecases.js';
import DataCard from "./DataCard";
function UseCase(props) {
  useEffect(() => {
    InitializeGoogleAnalytics();
    TrackGoogleAnalyticsEvent(props.ui_element, ApplicationDB, props.buttonId);
  }, [props.buttonId]);
  const nodeRef = React.useRef(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [showCTA, setShowCTA] = useState(false);
  const allGraphicSame = useRef(false);
  const [isCTA, setIsCTA] = useState(true);
  const [stepData, setStepData] = useState();
  const [ctaData, setCtaData] = useState();
  const [playAndPause, setPlayAndPause] = useGlobalState("playAndPause");
  const [isMuted, setIsMuted] = useGlobalState('IsMuted');
  const [currentSound, setCurrentSound] = useState();
  const [currentSoundIdx, setCurrentSoundIdx] = useState();
  const [isAutoPlay, setIsAutoPlay] = useState();
  const [audioClipList, setAudioClipList] = useState();
  const [audioPaths, setAudioPaths] = useState();
  const [solutionGraphicsData, setSolutionGraphicsData] = useState();
  const [audioVO1, setAudioVO1] = useGlobalState("audioVO1");
  const [audioVO2, setAudioVO2] = useGlobalState("audioVO2");
  const [audioPathVO1, setAudioPathVO1] = useGlobalState("audioPathVO1");
  const [audioPathVO2, setAudioPathVO2] = useGlobalState("audioPathVO2");
  const [firstVOPlayed, setFirstVOPlayed] = useState(false);
  const [playUCDirectly, setPlayUCDirectly] = useGlobalState("playUCDirectly");
  const [UcGuidedTour, setUcGuidedTour] = useGlobalState("UcGuidedTour");
  const [scene, setScene] = useGlobalState("scene");
  const [hasImage, sethasImage] = useGlobalState("hasImage");
  const [storyElementSelected, setStoryElementSelected] = useState();
  const [solutionsId, setSolutionsId] = useGlobalState("solutionsId");
  const [solutionsData, setSolutionsdata] = useState();
	const [showDataCard, setShowDataCard] = useGlobalState("showDC");
	const [dataObject, setDataObject] = useState(null);
  const [currentSlide, setCurrentSlide] = useGlobalState("currentSlide"); 
  var usecases = []


  const fetchSolutionsData = async () => {           
    var obj = [];  
    const baseAPIUrl = `${BaseAPI}solutions`;
    const address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/solutions.json`; //address for fetching sectiondata
    try {
      const response = await fetch(address); //fetch section data files for specific config id
      const data = await response.json();
      data.Solutions.forEach((element) => {
        obj.push(element);
      });
    } catch (error) {}
    setSolutionsdata(obj);
  }
  

  const fetchStepData = async (ucid) => {
    // fetch config files
    const apiurl = !packageApp ? `${BaseAPI}use_case_stories_emotion/${String(ucid)}?db=${ApplicationDB}` : `../../${ApplicationDB}/use_case_stories_emotion/${String(ucid)}.json`;
    const response = await fetch(apiurl);
    if (!response.ok) {
      throw new Error("Data could not be fetched.");
    } else {
      return response.json();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchUsecases();
        usecases = data;
      } catch (error) {
      }
    };
    fetchData();
    fetchSolutionsData();
  }, []);

	useEffect(() => {
    if (solutionsId != -1) {
			showSolutionBox(solutionsId);
		}
	}, [solutionsId]);

  const showSolutionBox = async (buttonId) => {
    if (buttonId === -1) return;
		setGlobalState("IsBackgroundBlur", true);
		setShowDataCard(true);
		setGlobalState("showDC", true);

    try {
			const obj = await solutionsData.find((element) => element.id === buttonId);
			setDataObject(obj);
			setShowDataCard(true);
			setGlobalState("showDC", true);	 
		} catch (error) {
		}
	};

  const handleDCCloseClick = () => {
		setShowDataCard(false);
		setGlobalState("showDC", false);
    let itemSelected = storyElementSelected;
    setStoryElementSelected();
    setCurrentSoundIdx(1001); // arbitrarily high so that the VO does not play
    setTimeout(() => {
      setStoryElementSelected (itemSelected);
    }, 300);
	};

	const handleDCPreButton = () => {
		document.getElementsByClassName("control-prev")[0]?.click();
		setCurrentSlide(currentSlide-1);
	};

	const handleDCNexButton = () => {
		document.getElementsByClassName("control-next")[0]?.click();
		setCurrentSlide(currentSlide+1);
  }

  useEffect(() => {
    const fetchData = async () => {
      setCurrentStep(1);
  
      const { useCaseID } = props;
      if (useCaseID && !playUCDirectly) {
        const res = await fetchStepData(useCaseID);
        setStepData(res);
      }
  
      try {
        const baseAPIUrl = `${BaseAPI}use_case_list/`;
        const address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/use_case_list.json`;
        const response = await fetch(address);
        const data = await response.json();
  
        const useCaseData = data.use_case_list.find((uc) => uc.use_case_id === useCaseID);
        const short_label = useCaseData?.short_label;
  
        const useCase = usecases.find((uc) => uc.id === useCaseID);
  
        if (useCase) {
          const canvas = document.getElementsByClassName("main-canvas")[0];
          const pos = Vector3.Project(
            new Vector3(useCase.position.x, useCase.position.y, useCase.position.z),
            Matrix.Identity(),
            scene.getTransformMatrix(),
            new Viewport(0, 0, canvas.width, canvas.height)
          );
          
          setGlobalState("HoverId", useCaseID);
          setGlobalState("HoverLabel", short_label);
          setGlobalState("clientXPosition1", pos.x);
          setGlobalState("clientYPosition1", pos.y);
          setIsUseCaseStoryOpen(true);
        }
      } catch (error) {
      }
    };
  
    fetchData();
  }, [props.useCaseID]);
  

  useEffect(() => {
    if (props.sectionData && playUCDirectly) {
      setCurrentStep(1);
      setStepData(props.sectionData);
      setShowDataCard(false);
    }
  }, [props.sectionData]);

  // Handle CTA
  useEffect(() => {
    if (stepData && stepData[String(currentStep)][0].step_type == "PS") {
      let catArray = [];
      stepData &&
        Object.keys(stepData).length &&
        stepData[String(currentStep)]?.map((obj, index) => {
          if (index > 0) {
            let catObj = {
              id: "",
            };
            catObj.id = obj.short_label.split("-")[0];
            catArray.push(catObj);
          }
        });

      setCtaData(catArray);
    }
  }, [currentStep]);

  useEffect(() => {
    let graphicData = [];
    stepData &&
      Object.keys(stepData).length &&
      stepData[String(currentStep)]?.map((obj, index) => {
        if (
          stepData &&
          (stepData[String(currentStep)][0].step_type ==
            "DS" ||
            stepData[String(currentStep)][0].step_type ==
            "PS" ||
            stepData[String(currentStep)][0].step_type ==
            "IM")
        ) {
          let graphicObj = {
            graphic: "",
          };
          if (index > 0) {
            graphicObj.graphic =
              obj.short_label.split("-")[1];
            graphicData.push(graphicObj);
          }
        }
      })

    if (graphicData){
      const isGraphicSame = graphicData.every(item => item.graphic === graphicData[0].graphic);
      allGraphicSame.current = isGraphicSame;
    }

    setSolutionGraphicsData(graphicData);
    sethasImage(true);
  }, [currentStep, stepData])

  var cta = 0;
  if (stepData) {
    for (let i = 1; i < Object.keys(stepData).length + 1; i++) {
      if (stepData[String(i)][0].step_type == "PS") {
        cta += 1;
        break;
      }
    }
  }

  // Handle VOs
  let Vosound;

  useEffect(() => {
    const src_url = !packageApp ?
      `${assetsLocation}${ApplicationDB}/audio/uc` + String(props.useCaseID) + "/"
      : `../../${ApplicationDB}/audio/uc` + String(props.useCaseID) + "/";
    if (stepData) {
      const dataForAI = new Object();
      for (const key in stepData) {
        const element = stepData[key];
        dataForAI[element[0].short_label] = new Object();
        for (var idx = 1; idx < element.length; idx++) {
          dataForAI[element[0].short_label][element[idx].short_label] = element[idx].long_desc
        }
      }
      setGlobalState("currentContext", dataForAI);
      const audioClips = [];
      const audio_Paths = [];
      audioClips.push(audioVO1.get(props.useCaseID));
      audio_Paths.push(audioPathVO1.get(props.useCaseID));
      audioClips.push(audioVO2.get(props.useCaseID));
      audio_Paths.push(audioPathVO2.get(props.useCaseID));
      for (let i = 12; i <= (Object.keys(stepData).length + 1) * 10; i++) {
        const path = src_url + String(i) + ".mp3";
        try {
          Vosound = new Howl({
            src: path,
            html5: true,
            onpause: false,
            preload: true
          });
          audioClips.push(Vosound);
          audio_Paths.push(path);
        } catch {
          audioClips.push("");
        }
      }
      setAudioClipList(audioClips);
      setAudioPaths(audio_Paths);
    }
    // Howler.stop();
  }, [stepData]);


  const handleDoubleClick = async (ucElement) => {
    if (!ucElement || !ucElement.short_label) {
      return;  // Return early if ucElement or short_label is missing
    }
    
    let shortLabel = ucElement.short_label;
    
    // Split the shortLabel by '-' to get the first part and subsequent parts
    let parts = shortLabel.split('-');
    
    let linkedSolution = parts[0];  

    // Return if the first part starts with an alphabet or is invalid
    if (!linkedSolution || !isNaN(linkedSolution.charAt(0))) {
      return;  
    }

    const solutionId = linkedSolution.match(/\d+/)[0]
    setSolutionsId(solutionId);
  
		setGlobalState("IsBackgroundBlur", true);
		setShowDataCard(true);
		setGlobalState("showDC", true);
    showSolutionBox(solutionId);
  };

  const createBgMusic = (emotion, volume = 0.15) => {
    return new Howl({
        src: !packageApp 
            ? `${assetsLocation}${ApplicationDB}/audio/uc_music/${emotion}.mp3` 
            : `../../${ApplicationDB}/audio/uc_music/${emotion}.mp3`,
        loop: true,
        preload: 'metadata',
        html5: true,
        volume: volume
    });
}


  const playFirstVo = () => {
    if (!stepData) return;

    const emotion = stepData[String(currentStep)][0].emotion;
    let idx = 10 * (currentStep - 1);
    setCurrentSoundIdx(idx);
    try {
        const bgMusic = createBgMusic(emotion, 0.15);
        if (!isMuted || props.IsGuidedTourOpen) bgMusic.play();
    }
    catch {
    }

    if (stepData)
      changeLightEmotion(scene, emotion);

  if (currentStep == 1 && firstVOPlayed == false && audioVO1 && audioVO1.size > 0 && audioVO1.has(props.useCaseID) && audioVO2 && audioVO2.size > 0 && audioVO2.has(props.useCaseID)) {
    setCurrentSound(audioVO1.get(props.useCaseID));
    setCurrentSoundIdx(idx);
    audioVO1.get(props.useCaseID).load();
    
    audioVO1.get(props.useCaseID).on("end", function (index) {
      idx++;
      setCurrentSound(audioVO2.get(props.useCaseID));
      setCurrentSoundIdx(idx);
      audioVO2.get(props.useCaseID).load();
      
      
      
      audioVO2.get(props.useCaseID).on("end", function (index) {
        setFirstVOPlayed(true);
        // Change images when going from VO1 to VO2
      if (!allGraphicSame.current) {
        switch (carouselType) {
          case "1":
            document.getElementsByClassName("control-next")[0].click();
            break;
          case "2":
            document.getElementsByClassName("awssld__controls__arrow-right")[0].click();
            break;
        }
      }
      });
      
      if (audioVO2.get(props.useCaseID) && (!isMuted || props.IsGuidedTourOpen)) audioVO2.get(props.useCaseID).play();
    });
    
    if (audioVO1.get(props.useCaseID) && (!isMuted || props.IsGuidedTourOpen)) audioVO1.get(props.useCaseID).play();
  }
}
  
  useEffect(() => {
    // executes every time step is changed
    setStoryElementSelected();
    if (isMuted && !props.IsGuidedTourOpen) 
      {
        setStoryElementSelected(1);
        //return;
      }
    playFirstVo();
  }, [currentStep, stepData, props.useCaseID]);

  useEffect(() => {
    // executes every time step is changed
    let idx = 10 * (currentStep - 1);
    if ((firstVOPlayed === true || currentStep > 1) && audioClipList && audioClipList.length > 0) {
      if (currentStep == 1) idx += 2;
      if (!isMuted || props.IsGuidedTourOpen) playClip(idx);
    }
  }, [currentStep, audioClipList, firstVOPlayed]);

  function playClip(idx) {
    if (idx >= currentStep * 10) {
      if (props.IsGuidedTourOpen) goToNext();
      return;
    }
    if (audioClipList == null) return;
    if (audioClipList[idx] == null || idx >= audioClipList.length) {
      if (props.IsGuidedTourOpen) {
        setGlobalState("UcGuidedTour", UcGuidedTour + 1);
        goToNext();
      }
      else{
        setIsAutoPlay(true);
        // bgMusic.volume(0.45)
        //increase bg music volume
      }
      return;
    }
    audioClipList[idx].load();
    audioClipList[idx].on("loaderror", function (index) {
      if (props.IsGuidedTourOpen) {
        goToNext();
      }
      else{
        setIsAutoPlay(true);
        //increase bg music volume
      }
    });

    audioClipList[idx].on("end", function (index) {
      if (storyElementSelected) return;
      if (
        (stepData && stepData[String(currentStep)][0]?.step_type == "DS") ||
        (stepData && stepData[String(currentStep)][0]?.step_type == "PS") ||
        (stepData && stepData[String(currentStep)][0]?.step_type == "IM")
      ) {
        let splitString = audioClipList[idx].load()._src.split(
          `uc${String(props.useCaseID)}/`
        );

        let splitUid = splitString[1].split(".mp3")[0];
        let voiceID = splitUid.toString().split("");
        if (
          voiceID[1] == (stepData && stepData[String(currentStep)].length - 1)
        ) {
          // setGlobalState("IsAutoPlay", true);  
        }
        if (idx % 10 != 0) {
          if (!allGraphicSame.current){
            switch (carouselType) {
              case "1":
                document.getElementsByClassName("control-next")[0].click();
                break;
              case "2":
                document.getElementsByClassName("awssld__controls__arrow-right")[0].click();
                break;
            }
          }
        }
      }
      idx++;
      playClip(idx);
    });

    try {
      if (audioClipList[idx]) {
        audioClipList[idx].play();
        setCurrentSound(audioClipList[idx]);
        setCurrentSoundIdx(idx);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    if (currentSound) {
      if (playAndPause) {
        currentSound.play();
      } else {
        currentSound.pause();
      }
    }
  }, [playAndPause]);

  useEffect(() => {
    if (showCTA) {
      Howler.stop();
    }
  }, [showCTA]);

  const goToPrevious = () => {
    if (showDataCard) return;
    //setSolutionGraphicsData()
    sethasImage(false);
    setStoryElementSelected();
    if (!playAndPause) {
      setGlobalState("playAndPause", true)
    }
    Howler.stop();
    let updatedAudios = [...audioClipList];
    if (currentStep == 1) {
      setFirstVOPlayed(false);
      playFirstVo();
      return;
    }
    for (let i = 10 * (currentStep - 1); i < 10 * currentStep; i++) {
      try {
        audioClipList[i].unload();
        updatedAudios[i] = new Howl({
          src: audioPaths[i],
          html5: true,
          onpause: false,
          preload: true
        });
      } catch {
      }
    }

    setAudioClipList(updatedAudios);
    setCurrentSound();
    setCurrentSoundIdx();
    setIsAutoPlay(false);
    const isFirstStep = currentStep === 1;
    let newStep;
    if (
      !isFirstStep &&
      stepData[String(currentStep - 1)][0]?.step_type == "VP"
    ) {
      newStep = isFirstStep ? 1 : currentStep - 2;
    } else {
      newStep = isFirstStep ? 1 : currentStep - 1;
    }

    if (newStep !== currentStep) {
      setCurrentStep(newStep);
    }
  };

  const elementSelectedEvent = (index) => {
    if (showDataCard) return;
    setGlobalState("playAndPause", true);
    setIsAutoPlay(false);
    Howler.stop();
    setCurrentSound(null);
    setStoryElementSelected(index);
  };


  useEffect(() => {
    var idx;
    if (!currentSoundIdx) return;
    if (!storyElementSelected) return;
    idx = currentSoundIdx % 10;
    if (!isMuted || props.IsGuidedTourOpen) {playClip(currentSoundIdx + storyElementSelected - idx)};
    setCurrentSoundIdx (currentSoundIdx + storyElementSelected - idx);
    if (idx == 0) {idx = 1};

    // loop from currentSoundIdx to new value
    if (storyElementSelected > idx) {
       for (let i = idx; i < storyElementSelected; i++) {
         switch (carouselType) {
           case "1":
              setTimeout(() => {
                if (document.getElementsByClassName("control-next")[0]) {
                document.getElementsByClassName("control-next")[0].click();
                }
              }, (i-idx) * 50);
            break;
          case "2":
            if (document.getElementsByClassName("awssld__controls__arrow-right")[0]) {
              document.getElementsByClassName("awssld__controls__arrow-right")[0].click();
            }
            break;
          default:
        }
      }
    } else if (storyElementSelected < idx) {
      for (let i = idx; i > storyElementSelected; i--) {
        switch (carouselType) {
          case "1":
            setTimeout(() => {
              if (document.getElementsByClassName("control-prev")[0]) {
              document.getElementsByClassName("control-prev")[0].click();
              }
            }, (idx-i) * 50);
            break;
          case "2":
            if (document.getElementsByClassName("awssld__controls__arrow-left")[0]) {
              document.getElementsByClassName("awssld__controls__arrow-left")[0].click();
            }
            break;
          default:
        }
      }
    }
  }, [storyElementSelected]); 


  const goToNext = () => {
    //setSolutionGraphicsData()
    if (showDataCard) return;
    if (!playAndPause) {
      setGlobalState("playAndPause", true)
    }
    setStoryElementSelected();
    Howler.stop();
    let updatedAudios = [...audioClipList];
    for (let i = 10 * (currentStep - 1); i < 10 * currentStep; i++) {
      try {
        audioClipList[i].unload();
        updatedAudios[i] = new Howl({
          src: audioPaths[i],
          html5: true,
          onpause: false,
          preload: true
        });
      } catch {
      }
    }

    if (currentStep == 1) {
      try {
        audioVO1.get(props.useCaseID).unload();
        audioVO1.set(props.useCaseID, new Howl({
          src: audioPathVO1.get(props.useCaseID),
          html5: true,
          onpause: false,
          preload: true
        }));
      } catch {
      }
      setAudioVO1(audioVO1);
      setGlobalState("audioVO1", audioVO1);

      try {
        audioVO2.get(props.useCaseID).unload();
        audioVO2.set(props.useCaseID, new Howl({
          src: audioPathVO2.get(props.useCaseID),
          html5: true,
          onpause: false,
          preload: true
        }));
      } catch {
      }
      setAudioVO2(audioVO2);
      setGlobalState("audioVO2", audioVO2);
      setFirstVOPlayed(false);
    }
    setAudioClipList(updatedAudios);

    setCurrentSound();
    //setCurrentSoundIdx();
    if (stepData) {
      setIsAutoPlay(false);
      const isLastStep = currentStep === Object.keys(stepData).length;
      let newStep;
      if (
        !isLastStep &&
        stepData[String(currentStep + 1)][0]?.step_type == "VP"
      ) {
        newStep = isLastStep ? Object.keys(stepData).length : currentStep + 2;
      } else {
        newStep = isLastStep ? Object.keys(stepData).length : currentStep + 1;
      }

      if (newStep !== currentStep) {
        setCurrentStep(newStep);
      } else if (cta > 0) {
        setShowCTA(true);
      } else {
        onClose();
        stopTour();
      }
    }
  };

  // Function to end tour

  const stopTour = () => {
    for (let i = 0; i < audioClipList.length; i++) {
      try {
        audioClipList[i].unload();
      } catch {
      }
    }
    Howler.stop();
    props.setShowUC(false);
    setGlobalState("showUC", false);
    setGlobalState("selectedButton", null);
    setGlobalState("playBgMusic", true);
    props.setShowUC(false);
    setGlobalState("useCase", 0);
    setGlobalState("isUseCaseStoryOpen", false);
    setIsUseCaseStoryOpen(false);
    setGlobalState("HoverUseCaseId", 0);
  };
  const onClose = () => {
    if (!props.IsGuidedTourOpen) {document.getElementById("reset").click();}
    props.setShowUC(false);
    setGlobalState("UcGuidedTour", UcGuidedTour + 1);
    setIsCTA(false);
    setShowCTA(false);
    setAudioClipList(null);
    setGlobalState("IsHomeButtonClick", true);
    setGlobalState("useCase", 0);
    setGlobalState("HoverUseCaseId", 0);
    resetLights(scene);
    // stopTour()
  };

  const DynamicCarousel = () => {
    switch (carouselType) {
      case "1":
        return <ReactResponsiveCarousel
          solutionGraphicsData={solutionGraphicsData}
        />
      case "2":
        return <ReactAwesomeCarousel
          solutionGraphicsData={solutionGraphicsData}
        />
    }
  }



  return (
    <>
      {isCTA ? (
        <>
          {!showCTA && (
            <div>
              {stepData && <div className={`box-wrap ${showDataCard ? 'blur-window' : 'unblur-window'} ${solutionGraphicsData && solutionGraphicsData.length > 0 ? 'shift-right' : ''}`}>
                <div className="box-buttons-group">
                  <svg onClick={goToPrevious} className="leftSvg" width="3vh" height="3vh" viewBox="0 0 30 30" fill="#1033A4" xmlns="http://www.w3.org/2000/svg">
                    <rect width="30" height="30" rx="15" transform="matrix(-1 0 0 1 30 0)" fill="#1033A4" fill-opacity="0.4" />
                    <rect x="-0.5" y="0.5" width="29" height="29" rx="14.5" transform="matrix(-1 0 0 1 29 0)" stroke="#FFFFFF" stroke-opacity="0.4" />
                    <path d="M13.6004 11.8L10.4004 15L13.6004 18.2" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18.6004 11.8L15.4004 15L18.6004 18.2" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {currentStep == (stepData && Object.keys(stepData).length) ?
                    <svg onClick={goToNext} className="rightCloseSvg" width="3vh" height="3vh" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="30" height="30" rx="15" fill="#1033A4" fill-opacity="0.4" />
                      <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="white" stroke-opacity="0.4" />
                      <path d="M21.5075 8.37791C21.415 8.28521 21.3051 8.21166 21.1841 8.16148C21.0631 8.1113 20.9335 8.08547 20.8025 8.08547C20.6715 8.08547 20.5418 8.1113 20.4209 8.16148C20.2999 8.21166 20.19 8.28521 20.0975 8.37791L15.2075 13.2579L10.3175 8.36791C10.2249 8.27533 10.115 8.20189 9.99404 8.15179C9.87308 8.10168 9.74343 8.07589 9.6125 8.07589C9.48157 8.07589 9.35192 8.10168 9.23095 8.15179C9.10999 8.20189 9.00008 8.27533 8.9075 8.36791C8.81492 8.4605 8.74148 8.57041 8.69137 8.69137C8.64127 8.81233 8.61548 8.94198 8.61548 9.07291C8.61548 9.20384 8.64127 9.33349 8.69137 9.45446C8.74148 9.57542 8.81492 9.68533 8.9075 9.77791L13.7975 14.6679L8.9075 19.5579C8.81492 19.6505 8.74148 19.7604 8.69137 19.8814C8.64127 20.0023 8.61548 20.132 8.61548 20.2629C8.61548 20.3938 8.64127 20.5235 8.69137 20.6445C8.74148 20.7654 8.81492 20.8753 8.9075 20.9679C9.00008 21.0605 9.10999 21.1339 9.23095 21.184C9.35192 21.2341 9.48157 21.2599 9.6125 21.2599C9.74343 21.2599 9.87308 21.2341 9.99404 21.184C10.115 21.1339 10.2249 21.0605 10.3175 20.9679L15.2075 16.0779L20.0975 20.9679C20.1901 21.0605 20.3 21.1339 20.421 21.184C20.5419 21.2341 20.6716 21.2599 20.8025 21.2599C20.9334 21.2599 21.0631 21.2341 21.184 21.184C21.305 21.1339 21.4149 21.0605 21.5075 20.9679C21.6001 20.8753 21.6735 20.7654 21.7236 20.6445C21.7737 20.5235 21.7995 20.3938 21.7995 20.2629C21.7995 20.132 21.7737 20.0023 21.7236 19.8814C21.6735 19.7604 21.6001 19.6505 21.5075 19.5579L16.6175 14.6679L21.5075 9.77791C21.8875 9.39791 21.8875 8.75791 21.5075 8.37791Z" fill="white" fill-opacity="0.8" />
                    </svg> :
                    <svg onClick={goToNext} className="blink rightSvg" width="3vh" height="3vh" viewBox="0 0 30 30" fill="#1033A4" xmlns="http://www.w3.org/2000/svg">
                      <rect width="30" height="30" rx="15" fill="#1033A4" fill-opacity="0.4" />
                      <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="#FFFFFF" stroke-opacity="0.4" />
                      <path d="M16.3996 11.8L19.5996 15L16.3996 18.2" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M11.3996 11.8L14.5996 15L11.3996 18.2" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  }
                </div>
                <SwitchTransition mode={"out-in"}>
                  <CSSTransition
                    key={true}
                    nodeRef={nodeRef}
                    addEndListener={(done) => {
                      nodeRef.current.addEventListener(
                        "transitionend",
                        done,
                        false
                      );
                    }}
                    classNames="fade"
                  >
                    <div style={{ maxHeight: '100%', overflowY: 'auto' }} ref={nodeRef}>
                      <div className="box-title">
                        {stepData &&
                          stepData[String(currentStep)]?.find(item => item.element === "0")?.short_label}
                      </div>

                      <div style={{ height: "100%" }} className="box-content">
                        {stepData &&
                          Object.keys(stepData).length &&
                          stepData[String(currentStep)]?.map((obj, index) => {
                            return (
                              obj.element !== "0" ? (
                                <div key={index} onClick={() => elementSelectedEvent(index)} className={((!storyElementSelected && audioClipList && stepData && currentStep && currentStep > 0 && audioClipList[stepData[String(currentStep)].length + currentStep * 10 - 10] != null && audioClipList[stepData[String(currentStep)].length + currentStep * 10 - 11]._duration != 0 && obj.element == String(currentSoundIdx % 10)) || (String(storyElementSelected) == obj.element)) ? "current-uc" : ""}
                                onDoubleClick={() =>
                                  handleDoubleClick(obj)
                                }>
                                  <div  className="content-title">
                                    {stepData &&
                                      (stepData[String(currentStep)][0]
                                        .step_type == "DS" ||
                                        stepData[String(currentStep)][0]
                                          .step_type == "PS" ||
                                        stepData[String(currentStep)][0]
                                          .step_type == "IM")
                                      ? obj.short_label.split("-").slice(2).join('-')
                                      : obj.short_label}
                                  </div>
                                  {obj.long_desc && obj.long_desc.length > 0 && (<div onClick={() => elementSelectedEvent(index)}  className="content-description" dangerouslySetInnerHTML={{ __html: obj.long_desc }}/>
                                  )}
                                </div>
                              ) : (
                                ""
                              )
                            );
                          })}
                      </div>
                      {stepData &&
                        (stepData[String(currentStep)][0].step_type == "DS" || stepData[String(currentStep)][0].step_type == "SS") && (
                          <>
                            {" "}

                            <div className="value-box-title">
                              {stepData &&
                                stepData[String(currentStep + 1)]?.[0]
                                  ?.short_label}
                            </div>
                            <div className="box-content-vp">
                              <div className="content-description-vp" style={{ marginTop: '0px', marginBottom: '0px' }}><ul>
                                {stepData &&
                                  stepData[String(currentStep + 1)]?.map(
                                    (obj, index) => {
                                      return (
                                        index > 0 ? (
                                          <div key={index} style={{ paddingBottom: '.8vh' }}>

                                            <li> {obj.short_label} </li>

                                          </div>

                                        ) : (
                                          ""
                                        )
                                      );
                                    }
                                  )}
                              </ul></div>
                            </div>
                          </>
                        )}
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>}
            </div>
          )}
          {showCTA && (
            <div className="CTA-Container">
              <div className="CTA-Card-Container">
                <div className="CTA-Title">Learn More</div>
                <div className="CTA-ButtonContainer">
                  {ctaData &&
                    ctaData.length > 0 &&
                    ctaData.map((item, index) => (
                      <div key={index} style={{ width: "50%" }}>
                        <Button id={item.id} />
                      </div>
                    ))}
                </div>
                <img style={{ position: 'absolute', top: '10%', right: '5%' }} src={Close} onClick={() => onClose()} />
              </div>
              <div className="CTA-cross-btn">
              </div>
            </div>
          )}
        </>
      ) : (
        ""
      )}
      {stepData &&  
        (stepData[String(currentStep)][0].step_type == "DS" ||
          stepData[String(currentStep)][0].step_type == "IM" ||
          stepData[String(currentStep)][0].step_type == "PS") && (
          <div className="left-container">
            {solutionGraphicsData && solutionGraphicsData.length > 0 && !showDataCard ?
              DynamicCarousel()
              : ''}
        	<DataCard
						data={dataObject}
						onClose={handleDCCloseClick}
						handlePreButton={handleDCPreButton}
						handleNexButton={handleDCNexButton}
						showCardContainer={showDataCard}
					/>
          </div>
        )}
    </>
  );
}

export default UseCase;