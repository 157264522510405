//export const assetsLocation = "https://dell-edge-test.s3-accelerate.amazonaws.com/BabylonAssets/"
export const assetsLocation = "https://repository.storyproc.com/"
export const ApplicationDB = "fieldassist"
export const BaseAPI = "https://bubble-backend.storyproc.com/"
export const MainMenuIsButtons = false
export const carouselType = "1"
export const packageApp = false
export const TVScreenSolutions = false
export const TRACKING_ID = "G-728XS2G376"
export function getApplicationDB() {
    return "fieldassist";
}